body{
  background-color: var(--primary);
  overflow-x: hidden;
}

i{
  color: var(--font-secondary);
}

#logo, #logo span {
  font-family: Aquire;
  font-size: 35px;
}

.nav-link{
  color: white;
}

.nav-link:focus{
  color: white;
}

.nav-link:hover{
  color: var(--font-primary);
}

.title{
  font-weight: bold;
  font-size: 60px;
}

.hero{
  width: 100%;
  background-color: var(--primary);
  background-size: cover;
  background-position: center 100%;
  background-attachment: fixed;
  position: relative;
}

.photo {
  filter: drop-shadow(0px 0px 10px var(--font-primary));
}

.yea{
  position: absolute;
  bottom: 0px;
  height: 100px;
  width: 100%;
  background: linear-gradient(to bottom, rgba(24, 42, 70, 0) 20%, rgba(24, 42, 70));
}

.yea-2 {
  position: absolute;
  bottom: -50px;
  width: 100%;
  height: 50px;
  background-color: var(--tertiary);
}

section{
  padding: 100px 10px;
  overflow-x: hidden;
}

section:nth-child(odd){
  background-color: var(--tertiary);
}

section:nth-child(even){
  background-color: var(--primary);
}

.photo-profile{
  width: 400px;
  aspect-ratio: 1/1;
  object-fit: cover;
  object-position: center 0%;
}


/* Project Section */
.card{
  overflow: hidden;
}

.project-image{
  width: 100%;
  aspect-ratio: 2/1;
  object-fit: cover;
  object-position: center 0%;
  border-radius: 10px;
}

.hover-image-container {
  position: relative;
  border-radius: 10px;
}

.project-detail-container {
  border-radius: 10px;
  transition: all 400ms ease;
  height: 0;
  width: 100%;
  z-index: 999;
  opacity: 0.9;
  position: absolute;
  bottom: 0;
  overflow: hidden;
}

.hover-image-container:hover .project-detail-container {
  height: 100%;
}

.project-detail {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translate(0, -50%);
}

.tag{
  z-index: 999;
  position: absolute;
  top: 15px;
  left: 15px;
}

.tag-item{
  border-radius: 20px;
  padding: 4px 10px;
  background: rgba(0,0,0,0.7);
}

.slick-dots > li > button::before, .slick-dots > li.slick-active > button::before{
  color: var(--font-secondary);
}

/* End of Project Section */

footer{
  z-index: 999;
  overflow-x: hidden;
  background-color: var(--secondary);
  box-shadow: 0px -10px 10px 2px #05162a;
}

.sosmed i{
  color: var(--font-secondary);
}

.sosmed:hover i{
  color: var(--font-primary);
}

.sosmed-white i{
  color: white;
}

.sosmed-white:hover i{
  color: var(--font-primary);
}

.loading{
  animation: animate 1s ease infinite;
}
@keyframes animate{
  0%{
    transform: rotate(0turn);
  }
  100%{
    transform: rotate(1turn);
  }
}

/* SVG */
.python{
  height: calc(1.275rem + .3vw)!important;
}

@media only screen and (max-width: 768px) {
  
}

/* lg */
@media only screen and (max-width: 992px) {
  .title, .title ~ h3{
    text-align: center;
  }

  .title ~ .d-flex{
    justify-content: center;
  }

  .photo {
    margin: 0 auto;
  }
  
  .hero .row {
    margin-top: 120px;
  }

  .photo-profile{
    margin: 0 auto;
    margin-bottom: 35px;
  }
}